export default {
    identityServerHost: null,
    clientId: null,
    scope: null,

    configure: function(identityServerHost, clientId, scope) {
        this.identityServerHost = identityServerHost;
        this.clientId = clientId;
        this.scope = scope;
    }
};