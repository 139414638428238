import React from 'react';

import { Collapse } from 'react-bootstrap';

class MobileCollapse extends React.Component {
    render() {
        return (
            <>
                <div className="d-block d-lg-none">
                    <Collapse in={this.props.in} >
                        <div>
                            {this.props.children}
                        </div>
                    </Collapse>
                </div>
                <div className="d-none d-lg-block">
                    {this.props.children}
                </div>
            </>
        )
    }
}

export default MobileCollapse;