import React from 'react';

import { ProgressBar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { minimumTime } from './Sleep'
import ActionButton from './ActionButton';
import FileDropzone from './FileDropzone';

class FileUpload extends React.Component {
    state = {
        mode: 'select',
        uploadProgress: 0,
    }

    isSelecting = () => this.state.mode === 'select';
    isUploading = () => this.state.mode === 'uploading';
    isUploaded = () => this.state.mode === 'uploaded';

    onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];

        await this.uploadFiles(file);
    }

    uploadFiles = async (file) => {
        this.setState({ mode: 'uploading', uploadProgress: 0 });
        
        var formData = new FormData();

        if (this.props.data) {
            for (const key in this.props.data) {
                formData.append(key, this.props.data[key]);
            }
        }

        formData.append(
            'fileData',
            file,
            file.name
        );

        const uploadTask = () => this.props.api.post(this.props.uploadUrl, formData, {
            onUploadProgress: this.onUploadProgress
        });

        await minimumTime(uploadTask, 1500);

        await this.setState({ mode: 'uploaded' });
    }

    onUploadProgress = async (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        await this.setState({ uploadProgress: percentCompleted });
    }

    deleteFile = async () => {
        let body = {
            ...this.props.data
        };

        await this.props.api.post(this.props.deleteUrl, body);

        await this.setState({ mode: 'select' });
    }

    componentDidMount() {
        if (this.props.isFileUploaded) {
            this.setState({ mode: 'uploaded' });
        }
    }

    render() {
        if (this.isUploaded()) {
            return (
                <div className="d-flex justify-content-between">
                    <span>A file has been uploaded.</span>
                    <ActionButton action={this.deleteFile}><FontAwesomeIcon icon="trash" /></ActionButton>
                </div>
            )
        } else if (this.isSelecting()) {
            return (
                <FileDropzone onDrop={this.onDrop} />
            )
        } else if (this.isUploading()) {
            return (
                <ProgressBar animated now={this.state.uploadProgress} label={`${this.state.uploadProgress}%`} />
            )
        }
    }
}

export default FileUpload;