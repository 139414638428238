import Config from './Config';
import { tryHandleSilentRenew } from './SilentRenew';

import AuthenticationRoutes from './ApiAuthorizationRoutes';
import { AuthenticationMenu } from './AuthenticationMenu';
import AuthorizeRoute from './AuthorizeRoute';
import AuthService from './AuthorizeService';

export function tryInitializeAuthentication(identityServerHost, clientId, scope) {
    Config.configure(identityServerHost, clientId, scope);

    if (tryHandleSilentRenew()) {
        return false;
    } else {
        return true;
    }
}

export const Authentication = {
    Routes: AuthenticationRoutes,
    Route: AuthorizeRoute,
    Nav: AuthenticationMenu,
    Service: AuthService,
    tryInitializeAuthentication: tryInitializeAuthentication
}