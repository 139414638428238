import React from 'react';

const Loading = () => <div></div>

const ThemeSelector = ({ children, theme, themes }) => {
  const SelectedTheme = themes[theme];

  return (
    <>
      <React.Suspense fallback={<Loading />}>
        <SelectedTheme />
        {children}
      </React.Suspense>
    </>
  )
}

export default ThemeSelector;