import React from 'react'

import Helmet from 'react-helmet'

import identify from './identify'

const FavIcon = ({ mode }) => {
    const brand = identify(mode)

    if (!brand) {
        return <></>
    }

    return (
        <Helmet>
            <link rel="icon" type="image/png" href={brand.FavIcon} />
        </Helmet>
    )
}

export default FavIcon