import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';

class DatePicker extends React.Component {
    state = {
        selected: null
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            this.setState({ selected: this.props.defaultValue });
        }
    }

    onChange = (date) => {
        this.setState({ selected: date });

        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    render() {
        return (
            <Row>
                <Col>
                    <Form.Control as={ReactDatePicker} 
                        selected={this.state.selected}
                        showWeekNumbers={this.props.showWeekNumbers}
                        showTimeSelect={this.props.showTimeSelect}
                        onChange={this.onChange}
                        dateFormat="MMMM d, yyyy h:mm aa"
                    />
                </Col>
            </Row>
        )
    }
}

export default DatePicker;