import configuration from './Config';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  SilentRenewCallback: 'silent-renew-callback',
  Profile: 'profile',
  Register: 'register'
};

const prefix = '/authentication';

export function getAuthConfig() {
  return {
    Scope: `openid ${configuration.scope}`,
    ClientId: configuration.clientId,
    Authority: configuration.identityServerHost
  }
}

export function getApplicationPaths() {
  return {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    SilentRenewCallback: `${prefix}/${LoginActions.SilentRenewCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: `${configuration.identityServerHost}/register`,
    IdentityManagePath: `${configuration.identityServerHost}/settings`
  }
}