import { useEffect } from 'react';

export default function useTimeout(func, interval) {
    useEffect(() => {
        async function execute() {
            await func()

            window.setTimeout(execute, interval)
        }

        window.setTimeout(execute, interval)
    }, [])
}