import FavIcon from './FavIcon'
import BrandSelector from './BrandSelector'
import Page from './Page'

import identify from './identify'
import Ailurus from './ailurus'

export default {
    identify: identify,
    Page: Page,
    BrandSelector: BrandSelector,
    FavIcon: FavIcon,
    Ailurus: Ailurus
}