import React from 'react'

import { Helmet } from 'react-helmet'

const Page = ({ title }) => {
    return (
        <Helmet title={title} />
    )
}

export default Page