import { useEffect } from 'react';

export default function useAsyncEffect(func, deps) {
    useEffect(() => {
        async function executeAsync() {
            await func()
        }

        executeAsync()
    }, deps)
}