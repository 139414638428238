import React from 'react'

import { Helmet } from 'react-helmet'

import identify from './identify'
import ThemeSelector from '../themes/ThemeSelector'
import FavIcon from './FavIcon'

const BrandSelector = ({ children, mode, themes }) => {
    const brand = identify(mode)

    return (
        <ThemeSelector theme={mode} themes={themes}> 
            {brand && (
                <Helmet titleTemplate={brand.TitleTemplate} />
            )}
            <FavIcon mode={mode} />
            {children}
        </ThemeSelector>
    )
}

export default BrandSelector