import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'react-toastify';

class InfoTextBox extends React.Component {
    copyValue = (value) => {
        navigator.clipboard.writeText(value);

        toast('Copied!');
    }

    render() {
        return (
            <InputGroup>
                <Form.Control type="text" readOnly value={this.props.value}></Form.Control>
                <InputGroup.Append>
                    <Button variant="primary" onClick={() => this.copyValue(this.props.value)}><FontAwesomeIcon icon="clipboard"></FontAwesomeIcon></Button>
                </InputGroup.Append>
            </InputGroup>
        )
    }
}

InfoTextBox.propTypes = {
    value: PropTypes.string
}

export default InfoTextBox;