import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Login } from './Login'
import { Logout } from './Logout'
import { getApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';

export default class ApiAuthorizationRoutes extends Component {
  render () {
    const applicationPaths = getApplicationPaths();

    return(
      <Fragment>
        <Route path={applicationPaths.ApiAuthorizationPrefix}>
          <Route exact path={applicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
          <Route exact path={applicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
          <Route exact path={applicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
          <Route exact path={applicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
          <Route exact path={applicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
          <Route exact path={applicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
          <Route exact path={applicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
          <Route exact path={applicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
        </Route>
      </Fragment>);
  }
}

function loginAction(name){
    return (<Login action={name}></Login>);
}

function logoutAction(name) {
    return (<Logout action={name}></Logout>);
}
