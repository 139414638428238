import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ActionButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    handleAction = async () => {
        if (this.props.action) {
            this.setState({ isLoading: true });

            try {
                await this.props.action();
            } catch {
                toast("Something went wrong.");
            }

            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <Button className={this.props.className} type={this.props.type} variant={this.props.variant} block={this.props.block} disabled={this.state.isLoading || this.props.disabled} onClick={this.handleAction}>
                {this.props.children}
                {this.state.isLoading && (
                    <span> <FontAwesomeIcon icon="spinner" spin /></span>
                )}
            </Button>
        )
    }
}

ActionButton.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    action: PropTypes.func
}

export default ActionButton;