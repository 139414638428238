import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Loading extends React.Component {
    render() {
        if (this.props.isLoading) {
            return (
                <Row className="text-center">
                    <Col>
                        <FontAwesomeIcon icon="spinner" size="lg" spin />
                    </Col>
                </Row>
            )
        }

        return this.props.children;
    }
}

Loading.propTypes = {
    isLoading: PropTypes.bool
}

export default Loading;