import axios from 'axios';
import AuthService from './auth/AuthorizeService';

export function createApi(baseUrl) {
    const instance = axios.create({
        baseURL: baseUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    instance.interceptors.request.use(function (config) {
        return AuthService.getAccessToken().then(function (token) {
            config.headers['Authorization'] = `Bearer ${token}`;

            return Promise.resolve(config);
        });
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    return instance;
}