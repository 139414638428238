export function identifyMode() {
    const rootDomain = window.location.hostname.replace(/.*\.([^.]*[^0-9][^.]*\.[^.]*[^.0-9][^.]*$)/, '$1');

    switch (rootDomain) {
        case 'ailurushosting.com':
            return 'Ailurus';
        case 'atlas2d.com':
            return 'Atlas';
        case 'eclipseorigins.com':
            return 'EclipseOrigins';
    }
}