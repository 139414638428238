export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export async function minimumTime(func, milliseconds) {
    const results = await Promise.all([
        func(),
        sleep(milliseconds)
    ]);

    return results[0];
}