import React, { Component, Fragment } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { getApplicationPaths } from './ApiAuthorizationConstants';

export class AuthenticationMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;

        const applicationPaths = getApplicationPaths();

        if (!isAuthenticated) {
            const registerPath = `${applicationPaths.Register}`;
            const loginPath = `${applicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${applicationPaths.Profile}`;
            const logoutPath = { pathname: `${applicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavDropdown title={userName} id="profile-menu">
                <NavDropdown.Item as={Link} to={profilePath}><i className="fas fa-cogs"></i> Manage</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={logoutPath}>Logout</NavDropdown.Item>
            </NavDropdown>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <Nav.Item>
                <Nav.Link as={Link} to={registerPath}>Register</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={loginPath}>Login</Nav.Link>
            </Nav.Item>
        </Fragment>);
    }
}
